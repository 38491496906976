@import '../../_colors.scss';

.search-form {
  display: flex;
  justify-content: center;

  $input_font_size: 16px;

  .search-input {
    border-radius: 30px;
    border: 1px solid $main_color;
    padding: 10px;
    font-size: $input_font_size;
    color: #fff;
    background: none;
    margin: 0 30px 0 0;

    &:hover, &:focus-visible {
      outline: none;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $main_color;
      font-size: $input_font_size;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: $main_color;
      font-size: $input_font_size;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      color: $main_color;
      font-size: $input_font_size;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: $main_color;
      font-size: $input_font_size;
    }
  }
}

@media screen and (max-width: 1000px) {
  .search-input {
    padding: 5px !important;
    font-size: 11px;
    color: $main_color;
    margin: 0;
  }
}
