* {
  font-family: 'Nunito', sans-serif;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: #3d3d3d;
}

.hidden {
  display: none !important;
}

.visible {
  display: block;
}