.contact-wrapper {
  width: 40%;
  background: lightgrey;
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem auto;
}

.contact-us-form {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
