.info-wrapper {
  width: 70%;

  .greetings-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .greetings-logo {
      margin-right: 10px;
      border-radius: 5px;
      width: 100px;
      height: 100px;
      background-image: url("../../img/logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
    }

    .greetings-text {
      color: #fff;
      font-weight: 500;
      font-size: 18px;
    }
  }
}
